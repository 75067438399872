<template>
  <div>
    <!-- SM screen -->
    <!-- <h5 class="d-block d-sm-none" style="max-width: 450px; min-width: 140px; word-wrap: break-word;"><strong>Image Creatives</strong></h5> -->
    <!-- * EXCEPT SM screen -->
    <!-- <h5 class="d-none d-sm-block" ><strong>Image Creatives</strong></h5> -->

    <CreativesTable :fileList="fileList" />
  </div>
</template>

<script>
export default {
  props: ['receivedCreatives'],
  data() {
    return {
      fileList: [],
    }
  },
  components: {
    CreativesTable: () => import('@/components/custom/imageCreativesTable/index.vue'),
  },
}
</script>

<style>
@media only screen and (min-width: 768px) {
  .picture-block {
    max-width: 170px !important;
    max-height: 170px !important;
  }
}
  @media only screen and (max-width: 768px) {
    .picture-block {
      max-width: 50px !important;
      max-height: 50px !important;
    }
}
</style>
